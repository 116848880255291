body {
  margin: auto auto auto auto;
  padding: 0;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #FFFFFF;
  background: url('../public/FUNDO2_tamanho_menor_com_cor.jpg') ;
  background-repeat: repeat-y;
  background-color: #041D4C;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-container {
  max-width: 1000px; /* Largura máxima do contêiner de texto */
  color: #000000;
  margin: 30px auto auto auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #f5fbfc;
  border-radius: 15px;
  border: 1px solid #ccc; 
  box-shadow: 0 0 2px rgb(255, 255, 255);
}
.content-container img {
  max-width: 100%; /* A imagem ocupa toda a largura da coluna */
  display: block;
}
.content-container .text {
  max-width: 50%; /* Largura máxima do texto */
  text-align: left; /* Alinhar o texto à esquerda */
}

.content-container-header {
  max-width: 1000px; /* Largura máxima do contêiner de texto */
  color: #000000;
  margin: 30px auto auto auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-image: url('../public/header_image.jpg') ;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  border: 1px solid #ccc; 
  box-shadow: 0 0 2px rgb(255, 255, 255);
}
.content-container-header img {
  max-width: 100%; /* A imagem ocupa toda a largura da coluna */
  display: block;
}
.content-container-header .text {
  max-width: 50%; /* Largura máxima do texto */
  text-align: left; /* Alinhar o texto à esquerda */
}

.logo-image {
  max-width: 100%; /* Set the maximum width to 100% of its container */
  height: auto;    /* Maintain the aspect ratio */
  display: block;  /* Remove any inline spacing */
  margin: 0 auto;  /* Center the image horizontally */
}

.btn-inscricao {
  background-color: #000d7e; /* Cor de fundo do botão */
  color: #fdff6e; /* Cor do texto do botão */
  padding: 10px 20px; /* Espaçamento interno do botão */
  border: none; /* Remove a borda padrão do botão */
  border-radius: 5px; /* Arredondamento das bordas */
  box-shadow: 0 0 15px #b9fa91;
  text-align: center; /* Centraliza o texto horizontalmente */
  text-decoration: none; /* Remove a decoração de link do texto */
  display: block;
  font-size: 20px; /* Tamanho da fonte */
  font-weight: 600;
  cursor: pointer; /* Altera o cursor ao passar por cima */
  margin: 0 auto;
}
.btn-inscricao:hover {
  background-color: #0056b3; /* Cor de fundo ao passar o mouse */
}

.btn-video {
  background-color: #1eff69; /* Cor de fundo do botão */
  color: #000000; /* Cor do texto do botão */
  padding: 10px 20px; /* Espaçamento interno do botão */
  border: none; /* Remove a borda padrão do botão */
  border-radius: 5px; /* Arredondamento das bordas */
  text-align: center; /* Centraliza o texto horizontalmente */
  text-decoration: none; /* Remove a decoração de link do texto */
  display: block;
  font-size: 16px; /* Tamanho da fonte */
  cursor: pointer; /* Altera o cursor ao passar por cima */
  margin: 0 auto;
}
.btn-video:hover {
  background-color: #17da58; /* Cor de fundo ao passar o mouse */
}

.section {
  padding: 30px 0;
  text-align: center;
  grid-template-columns: 1fr 2fr; /* Define uma coluna para a imagem e uma coluna para o texto */
  gap: 20px; /* Espaço entre a imagem e o texto */
}

/* Estilos de layout responsivo para dispositivos móveis */
@media (max-width: 768px) {
  .content-container {
      flex-direction: column; /* Alterado para posicionar a imagem acima do texto em dispositivos móveis */
  }
  .content-container img {
      max-width: 100%; /* A imagem ocupa toda a largura em dispositivos móveis */
  }
  .content-container .text {
      max-width: 100%; /* O texto ocupa toda a largura em dispositivos móveis */
      text-align: center; /* Centralizar o texto em dispositivos móveis */
  }

  .content-container-header {
    flex-direction: column; /* Alterado para posicionar a imagem acima do texto em dispositivos móveis */
    background-image: url('../public/header_image_mobile.jpg') ;
    background-size: cover;
    background-position: center;
  }
  .content-container-header img {
      max-width: 100%; /* A imagem ocupa toda a largura em dispositivos móveis */
  }
  .content-container-header .text {
      max-width: 100%; /* O texto ocupa toda a largura em dispositivos móveis */
      text-align: center; /* Centralizar o texto em dispositivos móveis */
  }
  .video-container iframe {
      width: 100%; /* Full width on smaller screens */
      height: auto; /* Maintain aspect ratio */
  }

  .empty-div-header {
    height: 60px;
  }

  .empty-div {
    height: 120px;
  }
}

/* Estilos para ocultar a lista de aulas por padrão */
.lesson-list {
    display: none;
}

/* Estilos para o cabeçalho do módulo */
.module-header {
    cursor: pointer;
    user-select: none; /* Evita que o texto seja selecionável */
}

/* Estilos para realçar o cabeçalho do módulo quando o mouse passa sobre ele */
.module-header:hover {
    background-color: #e7efcd;
}

/* Estilos para o formulário */
.form-container {
  background-color: #fbffd9;
  color: #000000;
  max-width: 500px;
  margin: 0 auto; 
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0 0 10px #fbffd9;
}
.form-container form {
  display: flex;
  flex-direction: column;
}
.form-container p {
  text-align: left;
}
.form-container label {
  text-align: left; /* Alinhar os rótulos à esquerda */
  margin-bottom: 0px;
}
.form-container input,
.form-container textarea {
  width: 100%; /* Os campos do formulário ocupam toda a largura do contêiner do formulário */
  padding: 5px 0px 5px 5px;
  margin-bottom: 2px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.form-container .acceptance-container {
  display: flex;
  align-items: center;
}
.form-container .acceptance-container label {
white-space: normal; /* Define a quebra de linha automática para o texto */
line-height: 1.2; /* Ajusta o espaçamento entre as linhas (experimente diferentes valores) */
}
.form-container .acceptance-container input[type="checkbox"] {
  margin-right: 1px; /* Espaçamento entre o checkbox e o rótulo */
  max-width: 10%;
}
.form-container button {
  background: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 2px; /* Adicione margem na parte superior do botão */
  font-size: 16px; /* Tamanho da fonte desejado, por exemplo, 16px */
}
.form-container button:hover {
  background-color: #0056b3; /* Cor de fundo ao passar o mouse */
}

.content-container-single-column {
  background: #f5fbfc;
  color: #000000;
  max-width: 1000px; /* Largura máxima do contêiner de texto */
  margin: 0 auto;
  display: grid;
  text-align: left; /* Alinhar o texto à esquerda */
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #ccc; /* Largura e cor da borda */
  box-shadow: 0 0 2px rgb(255, 255, 255); /* Sombra com deslocamento, difusão e cor */
}
.content-container-single-column img {
  max-width: 100%; /* A imagem ocupa toda a largura da coluna */
  display: block;
  margin: 0 auto; /* Centraliza a imagem horizontalmente */
}
.avisosobresenha {
font-size: 12px; /* Defina o tamanho da fonte desejado (por exemplo, 14 pixels) */
text-align: left;
}
.politicadeprivacidade {
font-size: 12px; /* Defina o tamanho da fonte desejado (por exemplo, 14 pixels) */
text-align: center;
}
.image-overlay {
display: none;
}
.privacidade-container {
  max-width: 600px;
  margin: 0 auto; 
  padding: 10px;
  border-radius: 5px;
  background: #E8F4FC;
  color:#000000;
  border: 1px solid #ccc; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: left;
}
.privacidade-container .text {
  max-width: 50%; /* Largura máxima do texto */
  text-align: left; /* Alinhar o texto à esquerda */
}

.offer-container {
  background: #f0f7c8;
  color: #000000;
  max-width: 600px; /* Largura máxima do contêiner de texto */
  margin: 0 auto;
  display: grid;
  text-align: left; /* Alinhar o texto à esquerda */
  padding: 0px 20px 20px 20px;
  border-radius: 25px;
  border: 1px solid #ccc; /* Largura e cor da borda */
  box-shadow: 0 0 10px #fbffd9; /* Sombra com deslocamento, difusão e cor */
}
.btn-pagamento {
  width: 100%; /* Set the button width to 100% */
  background-color: #007BFF; /* Cor de fundo do botão */
  color: #fff; /* Cor do texto do botão */
  padding: 10px 20px; /* Espaçamento interno do botão */
  border: none; /* Remove a borda padrão do botão */
  border-radius: 5px; /* Arredondamento das bordas */
  text-align: center; /* Centraliza o texto horizontalmente */
  text-decoration: none; /* Remove a decoração de link do texto */
  display: block; /* Change to block-level element */
  font-size: 16px; /* Tamanho da fonte */
  cursor: pointer; /* Altera o cursor ao passar por cima */
  margin: 0 auto; /* Center the button horizontally */
}
.btn-pagamento:hover {
  background-color: #0056b3; /* Cor de fundo ao passar o mouse */
}

.btn-pagamento-unique {
  width: 80%; /* Set the button width to 100% */
  background-color: #007BFF; /* Cor de fundo do botão */
  color: #fff; /* Cor do texto do botão */
  padding: 10px 20px; /* Espaçamento interno do botão */
  border: none; /* Remove a borda padrão do botão */
  border-radius: 5px; /* Arredondamento das bordas */
  text-align: center; /* Centraliza o texto horizontalmente */
  text-decoration: none; /* Remove a decoração de link do texto */
  display: block; /* Change to block-level element */
  font-size: 20px; /* Tamanho da fonte */
  cursor: pointer; /* Altera o cursor ao passar por cima */
  margin: 10px auto 10px auto; /* Center the button horizontally */
}
.btn-pagamento-unique:hover {
  background-color: #0056b3; /* Cor de fundo ao passar o mouse */
}

.header-container {
  text-align: left;
  padding: 5px 5px 5px 20px;
}

.new-header-container {
  width: 90%;
  text-align: left;
  padding: 1px 1px 1px 1px;
}
.urgente {
  color: #170069;
  margin-top: 5px;
  margin-bottom: 5px; 
}

.empty-div-header {
  width: 300px;
}

.empty-div {
  width: 350px;
}

.highlighted-heading {
  background-color: rgba(238, 255, 0, 0.66); /* Background color with some transparency */
  padding: 2px 4px; /* Add padding to create some space around the text */
  display: inline-block; /* Make the background cover only the text, not the entire width of the container */
}

.auth-container {
  background: #f5fbfc;
  color: #000000;
  max-width: 480px; /* Largura máxima do contêiner de texto */
  margin: 20px auto auto auto;
  display: grid;
  text-align: left; /* Alinhar o texto à esquerda */
  padding: 5px 20px 20px 20px;
  border-radius: 15px;
  border: 1px solid #ccc; /* Largura e cor da borda */
  box-shadow: 0 0 2px rgb(255, 255, 255); /* Sombra com deslocamento, difusão e cor */
}

.auth-container-pagarme {
  background: #f5fbfc;
  color: #000000;
  max-width: 360px; /* Largura máxima do contêiner de texto */
  margin: 20px auto auto auto;
  display: grid;
  text-align: left; /* Alinhar o texto à esquerda */
  padding: 5px 20px 20px 20px;
  border-radius: 15px;
  border: 1px solid #ccc; /* Largura e cor da borda */
  box-shadow: 0 0 2px rgb(255, 255, 255); /* Sombra com deslocamento, difusão e cor */
}

.formfield {
  margin: 0px 0px 15px 0px;
}